<template>
  <area-container>
    <area-nav :links="links"></area-nav>
    <area-content>
      <router-view></router-view>
    </area-content>
  </area-container>
</template>

<script>
export default {
  name: "MessagesLayout",
  data() {
    return {
      links: [
        {
          route: "messages.Messages",
          name: this.$tk("MessageLayout.Messages")
        },
        {
          route: "messages.Articles",
          name: this.$tk("MessageLayout.Articles")
        },
        {
          route: "messages.Guides",
          name: this.$tk("MessageLayout.Guides")
        },
        {
          route: "messages.System",
          name: this.$tk("MessageLayout.System")
        },
      ]
    }
  }
}
</script>
